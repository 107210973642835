import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CaseStartsByPartnerResponse, ChartParams, ChartResponse, ChartsService } from 'src/app/services/charts/charts.service';
import moment, { Moment } from 'moment-timezone';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Chart } from 'chart.js/auto';
import 'chartjs-adapter-moment';
import { CampaignItem, CampaignsService } from 'src/app/services/campaigns/campaigns.service';

@Component({
  selector: 'app-case-starts',
  templateUrl: './case-starts.component.html',
  styleUrls: ['./case-starts.component.scss']
})
export class CaseStartsComponent implements OnInit {
  loading = false;

  filterForm: FormGroup;

  campaigns: CampaignItem[] = [];

  casesStartedByPartners?: CaseStartsByPartnerResponse;
  casesStartedDataSums: Record<string, number> = {};
  private casesStartedData?: ChartResponse;
  private casesStartedCanvas?: Chart;
  private _casesStartedChart?: ElementRef<HTMLCanvasElement>;
  @ViewChild('casesStarted') set casesStartedChart(ref: ElementRef<HTMLCanvasElement>) {
    this._casesStartedChart = ref;
    this.setStartCaseChartData(ref.nativeElement, this.casesStartedData);
  };

  get campaign(): FormControl { return this.filterForm.get('campaign') as FormControl; }
  get from_date(): FormControl { return this.filterForm.get('from_date') as FormControl; }
  get to_date(): FormControl { return this.filterForm.get('to_date') as FormControl; }

  constructor(
    private chartsService: ChartsService,
    private fb: FormBuilder,
    private campaignsService: CampaignsService,
  ) {
    this.filterForm = this.fb.group({
      campaign: null,
      from_date: [moment().subtract(1, 'months').startOf('month'), [Validators.required]],
      to_date: [moment().subtract(1, 'months').endOf('month'), [Validators.required]],
    });
  }

  async ngOnInit() {
    const [campaignResult] = await Promise.all([
      this.campaignsService.getCampaigns(),
      this.getCharts(),
    ]);

    this.campaigns = campaignResult.campaigns;
  }

  async getCharts(): Promise<void> {
    const params: ChartParams = {
      from_date: this.from_date.value.format('YYYY-MM-DD'),
      to_date: this.to_date.value.format('YYYY-MM-DD'),
      campaign: this.campaign.value?.campaign,
    };

    const [
      caseStarts,
      caseStartsByPartner,
    ] = await Promise.all([
      this.chartsService.getChart('case-starts', params),
      this.chartsService.getCaseStartsByPartner(params),
    ]);

    this.casesStartedData = caseStarts;
    this.casesStartedByPartners = caseStartsByPartner;

    this.setStartCaseSums(caseStarts);
    this.setStartCaseChartData(this._casesStartedChart?.nativeElement, caseStarts);
  }

  quickFilter(type: 'current_month' | 'prev_month' | 'ytd'): void {
    if (type === 'current_month') {
      this.from_date.patchValue(moment().startOf('month'));
      this.to_date.patchValue(moment());
    } else if (type === 'prev_month') {
      this.from_date.patchValue(moment().subtract(1, 'month').startOf('month'));
      this.to_date.patchValue(moment().subtract(1, 'month').endOf('month'));
    } else if (type === 'ytd') {
      this.from_date.patchValue(moment().startOf('year'));
      this.to_date.patchValue(moment());
    }
  }

  readonly campaignDisplayWith = (campaign?: CampaignItem): string => {
    if (!campaign) {
      return '';
    }

    return `${campaign.source}/${campaign.campaign}`;
  };

  private setStartCaseSums(data: ChartResponse): void {
    for (const dataset of data.data.datasets) {
      this.casesStartedDataSums[dataset.label] = 0;
      for (const e of dataset.data) {
        this.casesStartedDataSums[dataset.label] += e.y;
      }
    }
  }

  private setStartCaseChartData(chart?: HTMLCanvasElement, data?: ChartResponse): void {
    if (!chart || !data) {
      return;
    }

    this.casesStartedCanvas?.destroy();

    const fromDate = this.from_date.value as Moment;
    const toDate = this.to_date.value as Moment;

    let unit: 'day' | 'month' = 'day';
    if (toDate.diff(fromDate, 'month') >= 3) {
      unit = 'month';
    }

    this.casesStartedCanvas = new Chart(
      chart,
      {
        data: data.data,
        type: 'bar',
        options: {
          scales: {
            x: {
              type: 'time',
              min: fromDate.valueOf(),
              max: toDate.valueOf(),
              time: {
                displayFormats: {
                  day: 'YYYY.MM.DD.',
                  month: 'YYYY.MM.',
                },
                unit,
              }
            },
          },
        }
      }
    );
  }
}
