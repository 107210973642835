<main>
  <h1>Kampány létrehozása</h1>
  <form
    id="create-campaign-form"
    (submit)="createCampaign()"
    [formGroup]="createForm"
  >
    <div class="flex" id="create-campaign-form-fields">
      <payee-form-field>
        <input type="text" payee-input formControlName="source" />
        <span payee-label>Source</span>
        <ng-container payee-error *ngIf="source.invalid && source.dirty">
          <mat-error *ngIf="source.hasError('required')">
            A mező kitöltése kötelező
          </mat-error>
          <mat-error *ngIf="source.hasError('maxlength')">
            A source nem lehet hosszabb
            {{ source.errors?.maxlength.requiredLength }}
            karakternél!
          </mat-error>
        </ng-container>
      </payee-form-field>
      <payee-form-field>
        <input type="text" payee-input formControlName="campaign" />
        <span payee-label>Kampány</span>
        <ng-container payee-error *ngIf="source.invalid && source.dirty">
          <mat-error *ngIf="campaign.hasError('required')">
            A mező kitöltése kötelező
          </mat-error>
          <mat-error *ngIf="campaign.hasError('maxlength')">
            A kampány nem lehet hosszabb
            {{ campaign.errors?.maxlength.requiredLength }}
            karakternél!
          </mat-error>
        </ng-container>
      </payee-form-field>
    </div>
    <mat-error *ngIf="createForm.hasError('unknown')">{{ createForm.errors?.unknown }}</mat-error>
    <button payee-button type="submit">Kampány létrehozása</button>
  </form>
  <table
    mat-table
    matSort
    matSortDirection="asc"
    matSortDisableClear
    matSortActive="created_at"
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    id="campaign-table"
  >
    <ng-container matColumnDef="source">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Source</th>
      <td mat-cell *matCellDef="let element">{{ element.source }}</td>
    </ng-container>

    <ng-container matColumnDef="campaign">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Kampány</th>
      <td mat-cell *matCellDef="let element">{{ element.campaign }}</td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        Kampány létrejötte
      </th>
      <td mat-cell *matCellDef="let element">{{ element.created_at }}</td>
    </ng-container>

    <ng-container matColumnDef="users">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a payee-button [routerLink]="'/admin/campaign-user/' + element.campaign">Felhasználók</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <router-outlet></router-outlet>
</main>
