import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AxiosError } from 'axios';
import { CampaignItem, CampaignsService } from 'src/app/services/campaigns/campaigns.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { unknown } from 'zod';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit, AfterViewInit {
  readonly dataSource = new MatTableDataSource<CampaignItem>();
  displayedColumns: string[] = [
    'source',
    'campaign',
    'created_at',
    'users',
  ];

  readonly createForm: FormGroup;
  get campaign(): FormControl { return this.createForm.get('campaign') as FormControl; }
  get source(): FormControl { return this.createForm.get('source') as FormControl; }

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private campaignsService: CampaignsService,
    private fb: FormBuilder,
    private helpersService: HelpersService,
    private snackbar: MatSnackBar,
  ) {
    this.createForm = this.fb.group({
      source: ['', [Validators.required, Validators.maxLength(16)]],
      campaign: ['', [Validators.required, Validators.maxLength(16)]],
    });
  }

  async ngOnInit(): Promise<void> {
    const result = await this.campaignsService.getCampaigns();
    this.dataSource.data = result.campaigns;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  async createCampaign(): Promise<void> {
    if (this.createForm.invalid) {
      this.helpersService.markAllChildrenAsDirty(this.createForm);
      return;
    }

    try {
      const result = await this.campaignsService.createCampaign({
        campaign: this.campaign.value,
        source: this.source.value,
      });

      this.dataSource.data = [
        ...this.dataSource.data,
        result.campaign,
      ];

      this.campaign.patchValue('');
      this.campaign.markAsPristine();
      this.source.patchValue('');
      this.source.markAsPristine();
    } catch (error) {
      if (error instanceof AxiosError) {
        const message = error.response?.data?.message;
        if (message) {
          this.createForm.setErrors({
            unknown: message,
          });
          return;
        }
      }
      this.snackbar.open('Valami hiba történt a kampány létrehozásakor!', 'OK', { duration: 5000 });
    }
  }
}
